<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["3日", "5日", "7日", "整体",],
        seriesData: [
          { value: 10, name: "3日归档率" },
          { value: 5, name: "5日归档率" },
          { value: 15, name: "7日归档率" },
          { value: 25, name: "整体归档率" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
